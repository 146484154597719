<template>
  <div class="row">
    <div class="col-12">
      <!-- 개선 목록 -->
      <c-table
        ref="table"
        title="LBL0001174"
        tableId="impr01"
        :columns="gridImpr.columns"
        :data="riskReduce.imprs"
        :merge="gridImpr.merge"
        :gridHeight="setheight"
        @linkClick="linkClick"
      >
      </c-table>
    </div>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
export default {
  name: 'charm-impr',
  props: {
    riskReduce: {
      type: Object,
      default: function() {
        return {
          imprs: [],
          riskbooks: [],
        }
      },
    },
    height: {
      type: String,
      default: function() {
        return '';
      },
    },
  },
  data() {
    return {
      gridImpr: {
        merge: [
          { index: 0, colName: 'processName' },
          { index: 1, colName: 'mdmChemMaterialId' },
          { index: 2, colName: 'mdmChemMaterialId' },
        ],
        columns: [],
        data: [],
        height: '500px'
      },
      popupOptions: {
        target: null,
        title: '',
        visible: false,
        width: '80%',
        param: {},
        closeCallback: null,
      },
      editable: true,
      imprData: null,
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  computed: {
    setheight() {
      let pxRemove = this.height.replace('px', '');
      return this.height && this.height.indexOf('px') > -1 && !isNaN(pxRemove) ? (Number(pxRemove) - 60) + 'px' : '500px'
    }
  },
  methods: {
    init() {
      // role setting
      // url setting
      // code setting
      this.$comm.getStepItems('IBM_STEP_CD').then(_result => {
        this.gridImpr.columns = [
          {
            name: 'processName',
            field: 'processName',
            label: 'LBLPROCESS',
            align: 'left',
            style: 'width:100px',
            sortable: false,
          },
          {
            name: 'materialName',
            field: 'materialName',
            // 화학자재
            label: 'LBL0000356',
            align: 'left',
            style: 'width:150px',
            sortable: false,
          },
          {
            name: 'existingRiskManagementActivities',
            field: 'existingRiskManagementActivities',
            // 현재안전보건조치
            label: 'LBL0001148',
            align: 'left',
            style: 'width:280px',
            sortable: false,
          },
          {
            name: 'addingRiskManagementActivies',
            field: 'addingRiskManagementActivies',
            // 감소대책
            label: 'LBL0001149',
            align: 'left',
            style: 'width:280px',
            sortable: false,
          },
          {
            name: 'ibmTitle',
            field: 'ibmTitle',
            label: 'LBLTITLE',
            align: 'left',
            type: 'link',
            style: 'width:280px',
            sortable: true,
          },
          {
            name: 'ibmStepCd',
            field: 'ibmStepCd',
            label: 'LBLPROGRESS',
            align: 'center',
            style: 'width:80px',
            type: 'tag',
            colorItems: _result,
            sortable: false
          },
          {
            name: 'improveRequest',
            field: 'improveRequest',
            // 요청부서정보
            label: 'LBL0001016',
            align: 'center',
            style: 'width:250px',
            sortable: true,
          },
          {
            name: 'actionCompleteRequestDate',
            field: 'actionCompleteRequestDate',
            // 조치완료요청일
            label: 'LBL0001017',
            align: 'center',
            style: 'width:100px',
            sortable: true,
          },
          {
            name: 'actionDeptName',
            field: 'actionDeptName',
            // 조치부서
            label: 'LBL0001018',
            align: 'center',
            style: 'width:100px',
            sortable: true,
          },
        ]
      });
      // list setting
    },
    research() {
      this.$emit('research');
    },
    /* eslint-disable no-unused-vars */
    linkClick(row, col, index) {
      this.imprData = row;
      this.popupOptions.title = 'LBLIMPROVE';
      this.popupOptions.param = {
        sopImprovementId: row.sopImprovementId,
      };
      this.popupOptions.target = () => import(`${'@/pages/sop/ibm/imprDetail.vue'}`);
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closeImprPopup;
    },
    closeImprPopup(result) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      if (result && result.col1) {
        this.$_.extend(this.imprData, result.col1)
        this.$refs['table'].$refs['compo-table'].resetVirtualScroll();
        this.research();
      }
    },
  }
};
</script>